import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes, filterOutDocsPublishedInTheFuture } from "../lib/helpers";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Container from "../components/container";
import ProjectList from "../components/project-list";
import BlockText from "../components/block-text";
import Slideshow from "../components/slideshow";
import ArrowTopRight from "../components/icon/ArrowTopRight";
import SiteTitle from "../components/site-title";
import { cn } from "../lib/helpers";
import * as styles from "./index.module.css";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    homePage: sanityHomePage {
      title
      _rawBio
      contactLinks {
        _key
        _type
        text
        url
      }
    }
    projects: allSanityProject(
      sort: { fields: [publishedAt], order: DESC }
      filter: { publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          title
          _rawDescription
          images {
            _key
            _type
            alt
            _rawAsset
            _rawHotspot
            _rawCrop
          }
          link
          tags {
            _id
            name
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data, errors }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const homePage = (data || {}).homePage;
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects).filter(filterOutDocsPublishedInTheFuture)
    : [];

  const [activeProjectIndex, setActiveProjectIndex] = useState(null);
  const [projectListActive, setProjectListActive] = useState(false);

  const prevActiveIdx = useRef(null);
  useEffect(() => {
    prevActiveIdx.current = activeProjectIndex;
  });

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout projectListActive={projectListActive}>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <div className={cn(styles.bg, projectListActive && styles.inactive)} />
      <Slideshow
        key={activeProjectIndex !== null ? activeProjectIndex : prevActiveIdx.current}
        images={
          projectNodes[activeProjectIndex]?.images ||
          projectNodes[prevActiveIdx.current]?.images ||
          []
        }
        projectListActive={projectListActive}
      />
      <div className={cn(styles.siteTitle, projectListActive && styles.inactive)}>
        <SiteTitle />
      </div>
      <Container>
        {homePage._rawBio && (
          <div className={cn(styles.bioWrapper, projectListActive && styles.inactive)}>
            <h2 className={styles.bioLabel}>Bio</h2>
            <div className={styles.bioText}>
              <BlockText blocks={homePage._rawBio} />
            </div>
          </div>
        )}
        {homePage.contactLinks.length > 0 && (
          <div className={cn(styles.contactWrapper, projectListActive && styles.inactive)}>
            <h2 className={styles.contactLabel}>
              Contact
              <span className={styles.arrowIcon}>
                <ArrowTopRight />
              </span>
            </h2>
            <ul className={styles.contactList}>
              {homePage.contactLinks.map((link, index) => (
                <li key={`link-${index}`} className={styles.contactListItem}>
                  <a href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {projectNodes && (
          <ProjectList
            nodes={projectNodes}
            activeProjectIndex={activeProjectIndex}
            setActiveProjectIndex={setActiveProjectIndex}
            setProjectListActive={setProjectListActive}
          />
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
