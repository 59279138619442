import React, { useEffect, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import * as styles from "./slideshow.module.css";

const SLIDESHOW_INTERVAL = 2000;

const SlideshowCursor = ({ activeIdx, totalImages }) => {
  const [mouseX, setMouseX] = useState(null);
  const [mouseY, setMouseY] = useState(null);

  const setMousePosition = e => {
    setMouseX(e.pageX || e.touches[0]?.pageX);
    setMouseY(e.pageY || e.touches[0]?.pageY);
  };

  useEffect(() => {
    if (document) {
      document.addEventListener("mousemove", setMousePosition);
    }

    return () => {
      if (document) {
        document.removeEventListener("mousemove", setMousePosition);
      }
    };
  }, []);

  if (!mouseX || !mouseY) {
    return <></>;
  }

  return (
    <div className={styles.cursor} style={{ top: `${mouseY}px`, left: `${mouseX}px` }}>
      <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
          className={styles.path}
          cx="15"
          cy="15"
          r="15"
          stroke="#E84600"
          strokeWidth="1.5"
          fill="none"
        />
      </svg>
      <div className={styles.inner}>
        <span>
          {activeIdx + 1 || 1}/{totalImages}
        </span>
      </div>
    </div>
  );
};

const Slideshow = ({ images, projectListActive }) => {
  const [activeIdx, setActiveIdx] = useState(0);
  const timeoutRef = useRef(null);

  const imageUrls = images.map(img => imageUrlFor(img._rawAsset).url());
  const isLastSlide = activeIdx === images.length - 1;

  const updateSlide = () => {
    setActiveIdx(isLastSlide ? 0 : activeIdx + 1);
  };

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    return () => {
      resetTimeout();
    };
  }, []);

  useEffect(() => {
    if (!projectListActive) {
      resetTimeout();
      return;
    }

    resetTimeout();
    timeoutRef.current = setTimeout(updateSlide, SLIDESHOW_INTERVAL);

    return () => {
      resetTimeout();
    };
  }, [activeIdx, projectListActive]);

  if (!images.length) {
    return null;
  }

  return (
    <>
      {!isMobile && projectListActive && (
        <SlideshowCursor activeIdx={activeIdx} totalImages={images.length} />
      )}
      {/* <SlideshowCursor activeIdx={activeIdx} totalImages={images.length} /> */}
      {imageUrls.map((img, index) => (
        <div
          key={`bg-img-${index}`}
          className={cn(styles.backgroundImage, index === activeIdx && styles.active)}
          style={{ backgroundImage: `url(${img})` }}
        />
      ))}
    </>
  );
};

export default Slideshow;
