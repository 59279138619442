import React from "react";

const ArrowTopRight = () => (
  <svg viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
    <path
      stroke="currentColor"
      d="M0.447369 8.55263L8.5 0.500002M8.5 0.500002L8.92105e-07 0.500001M8.5 0.500002L8.5 9"
    />
  </svg>
);

export default ArrowTopRight;
