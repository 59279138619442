import React, { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import ProjectRow from "./project-row";
import { cn } from "../lib/helpers";
import * as styles from "./project-list.module.css";

const TOUCH_DURATION = 300;

function ProjectList({ nodes, activeProjectIndex, setActiveProjectIndex, setProjectListActive }) {
  const projectListActive = activeProjectIndex !== null;
  const timeoutRef = useRef(null);
  const projectListRef = useRef(null);

  const resetProjectList = () => {
    setActiveProjectIndex(null);
  };

  const onProjectInteract = (images, projIndex) => {
    if (!images.length) {
      resetProjectList();
    }

    setActiveProjectIndex(projIndex);
  };

  const onProjectTouchStart = (e, images, projIndex) => {
    timeoutRef.current = setTimeout(() => {
      onProjectInteract(images, projIndex);
    }, TOUCH_DURATION);
  };

  const onProjectTouchEnd = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      resetProjectList();
      setProjectListActive(false);
    }
  };

  return (
    <section className={styles.root} ref={projectListRef}>
      <div className={cn(styles.headingRow, projectListActive && styles.projectRowInactive)}>
        <span className={styles.titleHeading}>Select Clients</span>
        <span className={styles.tagsHeading}>Scope</span>
        <span className={styles.descriptionHeading}>Description</span>
      </div>
      <ul
        className={styles.projectList}
        onMouseEnter={() => {
          setProjectListActive(true);
        }}
        onMouseLeave={() => {
          resetProjectList();
          setProjectListActive(false);
        }}
        onTouchStart={() => {
          setProjectListActive(true);
        }}
      >
        {nodes &&
          nodes.map((node, index) => (
            <li
              key={node.id}
              className={cn(
                styles.projectRow,
                isMobile && styles.noSelect,
                projectListActive &&
                  (activeProjectIndex === index
                    ? styles.projectRowActive
                    : styles.projectRowInactive)
              )}
              onMouseOver={!isMobile ? () => onProjectInteract(node.images, index) : undefined}
              onTouchStart={isMobile ? e => onProjectTouchStart(e, node.images, index) : undefined}
              onTouchEnd={isMobile ? onProjectTouchEnd : undefined}
            >
              <ProjectRow
                {...node}
                projectListActive={projectListActive}
                isRowActive={activeProjectIndex === index}
              />
            </li>
          ))}
      </ul>
    </section>
  );
}

export default ProjectList;
