// extracted by mini-css-extract-plugin
export var display = "project-list-module--display--pjKIh";
export var heading = "project-list-module--heading--0yjh5";
export var bodySmall = "project-list-module--bodySmall--3RfB-";
export var root = "project-list-module--root--c3djR";
export var projectList = "project-list-module--projectList--ntQKf";
export var headingRow = "project-list-module--headingRow--zFqBv";
export var projectRow = "project-list-module--projectRow--moqjZ";
export var projectRowActive = "project-list-module--projectRowActive--TkJ+K";
export var tagMore = "project-list-module--tagMore--J3u0O";
export var projectRowInactive = "project-list-module--projectRowInactive--ypr-W";
export var titleHeading = "project-list-module--titleHeading--jAIRY project-list-module--heading--0yjh5";
export var tagsHeading = "project-list-module--tagsHeading--04e0U project-list-module--heading--0yjh5";
export var descriptionHeading = "project-list-module--descriptionHeading--XQ0EM project-list-module--heading--0yjh5";
export var hideCursor = "project-list-module--hideCursor--ghWmK";
export var noSelect = "project-list-module--noSelect--P7EX3";