// extracted by mini-css-extract-plugin
export var display = "project-row-module--display--wi3Wl";
export var heading = "project-row-module--heading--OlI5X";
export var bodySmall = "project-row-module--bodySmall--9sjrY";
export var title = "project-row-module--title--iOu8i";
export var arrowIcon = "project-row-module--arrowIcon--5iSgL";
export var tagList = "project-row-module--tagList--VmABM";
export var tagListItem = "project-row-module--tagListItem---t3qN project-row-module--bodySmall--9sjrY";
export var tagMore = "project-row-module--tagMore--Bcjlv";
export var active = "project-row-module--active--eF2kN";
export var inactive = "project-row-module--inactive--prqHO";
export var description = "project-row-module--description--0jL1I project-row-module--bodySmall--9sjrY";