// extracted by mini-css-extract-plugin
export var display = "index-module--display--eBpV7";
export var heading = "index-module--heading--6HXzj";
export var bodySmall = "index-module--bodySmall--toE6R";
export var bg = "index-module--bg--Vl8VC";
export var bioWrapper = "index-module--bioWrapper--REijP";
export var bioLabel = "index-module--bioLabel--e110h index-module--heading--6HXzj";
export var bioText = "index-module--bioText--lfnEA index-module--display--eBpV7";
export var contactWrapper = "index-module--contactWrapper--X1T5b";
export var contactLabel = "index-module--contactLabel--U4psz index-module--heading--6HXzj";
export var arrowIcon = "index-module--arrowIcon--PaWeN";
export var contactList = "index-module--contactList--HGIPS";
export var contactListItem = "index-module--contactListItem--n3ysg";
export var siteTitle = "index-module--siteTitle--1ewyS";
export var fadeIn = "index-module--fade-in--DkiFo";
export var inactive = "index-module--inactive--Zr-bd";