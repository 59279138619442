import React from "react";
import { cn } from "../lib/helpers";
import ArrowTopRight from "./icon/ArrowTopRight";
import BlockText from "./block-text";

import * as styles from "./project-row.module.css";

const ProjectRow = ({ title, _rawDescription, tags, link, projectListActive, isRowActive }) => {
  return (
    <>
      <h3 className={styles.title}>
        {link ? (
          <a href={link}>
            {title}{" "}
            <span className={styles.arrowIcon}>
              <ArrowTopRight />
            </span>
          </a>
        ) : (
          title
        )}
      </h3>
      {tags && (
        <ul className={styles.tagList}>
          {tags.map(tag => (
            <li key={tag.name} className={styles.tagListItem}>
              {tag.name}
            </li>
          ))}
          <li
            className={cn(
              styles.tagListItem,
              styles.tagMore,
              projectListActive && (isRowActive ? styles.active : styles.inactive)
            )}
          >
            & More
          </li>
        </ul>
      )}
      {_rawDescription && (
        <div className={styles.description}>
          <BlockText blocks={_rawDescription} />
        </div>
      )}
    </>
  );
};

export default ProjectRow;
