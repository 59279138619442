import React from "react";

const SiteTitle = () => (
  <svg viewBox="0 0 1368 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 86.2114H10.6561V18.2418L38.2059 85.95H48.3421L74.5924 18.2418V86.2114H85.2485V1.24944H69.6542L43.4039 71.049L16.114 1.24944H0V86.2114Z"
      fill="#B7C1CD"
    />
    <path
      d="M159.84 86.2114H172.315L138.268 1.24944H126.832L92.5247 86.2114H105L113.837 63.2063H151.003L159.84 86.2114ZM117.735 53.0109L132.42 14.8434L147.105 53.0109H117.735Z"
      fill="#B7C1CD"
    />
    <path
      d="M211.298 1.24944H179.07V86.2114H211.298C234.689 86.2114 251.843 68.5654 251.843 43.7304C251.843 18.8954 234.689 1.24944 211.298 1.24944ZM210.258 75.4931H189.726V11.7063H210.258C227.932 11.7063 240.407 25.4309 240.407 43.7304C240.407 62.0299 227.932 75.4931 210.258 75.4931Z"
      fill="#B7C1CD"
    />
    <path
      d="M274.946 75.7545V48.6974H316.79V38.3713H274.946V11.4449H323.548V1.24944H264.29V86.2114H324.068V75.7545H274.946Z"
      fill="#B7C1CD"
    />
    <path
      d="M356.809 86.2114V1.24944H392.676C407.751 1.24944 418.407 10.5299 418.407 23.8624C418.407 31.705 413.208 39.6784 405.931 42.2926C414.248 44.6454 420.486 52.8802 420.486 61.7685C420.486 76.4081 410.09 86.2114 395.275 86.2114H356.809ZM367.465 37.8484H390.597C400.473 37.8484 407.231 32.2279 407.231 24.3852C407.231 16.804 400.473 11.4449 390.597 11.4449H367.465V37.8484ZM367.465 75.7545H392.156C402.033 75.7545 409.31 69.6111 409.31 61.2456C409.31 53.403 402.033 47.7824 392.156 47.7824H367.465V75.7545Z"
      fill="#B7C1CD"
    />
    <path
      d="M482.842 1.24944L460.491 50.3967L436.32 1.24944H423.584L454.903 62.1606V86.2114H465.559V62.1606L495.318 1.24944H482.842Z"
      fill="#B7C1CD"
    />
    <path
      d="M585.286 63.4363C582.085 77.0298 575.802 82.8726 564.303 82.8726H551.974C546.757 82.8726 544.979 80.7263 544.979 75.3604V44.5962H559.442C565.725 44.5962 569.756 48.4119 571.534 56.9973H573.194L569.519 28.8564H567.859C568.452 37.4417 565.488 41.2575 558.494 41.2575H544.979V5.12738H562.406C574.498 5.12738 580.662 10.6125 583.982 24.4444H585.642L581.729 0H580.07C579.358 1.31166 577.462 1.78862 573.194 1.78862H520.321V3.458C528.501 4.29268 530.99 6.43903 530.99 12.2818V75.7182C530.99 81.4417 528.501 83.5881 520.321 84.542V86.2114H583.863L586.946 63.4363H585.286Z"
      fill="#B7C1CD"
    />
    <path
      d="M592.159 1.78862V3.458C600.339 4.29268 602.828 6.43903 602.828 12.2818V75.7182C602.828 81.4417 600.339 83.5881 592.159 84.542V86.2114H629.383C655.583 86.2114 672.417 69.3984 672.417 41.9729C672.417 16.813 654.871 1.78862 627.605 1.78862H592.159ZM624.286 82.8726C619.307 82.8726 616.817 80.3686 616.817 75.3604V5.12738H625.59C644.558 5.12738 657.598 19.9133 657.598 44.5962C657.598 66.8943 645.506 82.8726 629.383 82.8726H624.286Z"
      fill="#B7C1CD"
    />
    <path
      d="M774.437 3.458C783.566 3.57724 784.87 7.27371 781.669 15.5014L762.464 66.7751L743.615 15.5014C740.532 7.27371 741.836 3.57724 750.609 3.458V1.78862H716.23V3.458C722.394 3.57724 725.832 8.34688 729.033 16.5745L731.523 23.0136L715.163 66.8943L696.314 15.5014C693.231 7.27371 694.417 3.57724 703.189 3.458V1.78862H668.929V3.458C675.093 3.57724 678.531 8.34688 681.613 16.5745L708.998 88H710.539L733.301 27.664L756.418 88H757.959L784.87 16.5745C788.426 7.15447 791.745 3.57724 796.725 3.458V1.78862H774.437V3.458Z"
      fill="#B7C1CD"
    />
    <path
      d="M825.276 0H823.617L793.979 71.3062C790.067 80.7263 786.511 84.4228 780.939 84.542V86.2114H805.479V84.542C795.876 84.4228 793.505 81.084 797.18 72.3794L802.752 58.6667H834.76L840.451 72.3794C844.126 81.084 841.992 84.4228 832.271 84.542V86.2114H869.377V84.542C863.212 84.4228 859.418 80.7263 855.388 71.4255L825.276 0ZM818.638 19.6748L833.338 55.3279H804.175L818.638 19.6748Z"
      fill="#B7C1CD"
    />
    <path
      d="M917.56 47.935C929.771 44.7154 936.884 36.7263 936.884 25.5176C936.884 10.851 924.555 1.78862 904.164 1.78862H868.837V3.458C876.779 4.0542 879.506 5.96206 879.506 12.9973V74.8835C879.506 81.9187 876.779 83.8266 868.837 84.542V86.2114H904.164V84.542C896.577 83.8266 893.495 82.2764 893.495 74.8835V49.4851H902.267L922.302 79.4146C923.843 81.9187 924.792 83.8266 925.029 86.2114H948.502V84.4228C945.419 84.065 940.085 80.9648 937.358 76.6721L917.56 47.935ZM901.082 5.12738C914.715 5.12738 921.947 14.0705 921.947 25.5176C921.947 36.8455 914.596 46.1463 901.082 46.1463H893.495V5.12738H901.082Z"
      fill="#B7C1CD"
    />
    <path
      d="M950.978 1.78862V3.458C959.158 4.29268 961.647 6.43903 961.647 12.2818V75.7182C961.647 81.4417 959.158 83.5881 950.978 84.542V86.2114H988.202C1014.4 86.2114 1031.24 69.3984 1031.24 41.9729C1031.24 16.813 1013.69 1.78862 986.424 1.78862H950.978ZM983.105 82.8726C978.126 82.8726 975.636 80.3686 975.636 75.3604V5.12738H984.409C1003.38 5.12738 1016.42 19.9133 1016.42 44.5962C1016.42 66.8943 1004.33 82.8726 988.202 82.8726H983.105Z"
      fill="#B7C1CD"
    />
    <path
      d="M1126.26 0C1125.55 1.31166 1123.53 1.78862 1119.38 1.78862H1071.49C1067.22 1.78862 1065.32 1.31166 1064.61 0H1062.95L1059.04 24.4444H1060.7C1064.02 10.6125 1070.18 5.12738 1082.28 5.12738H1088.44V75.3604C1088.44 80.9648 1086.42 83.5881 1077.89 84.4228V86.2114H1112.86V84.4228C1104.33 83.5881 1102.43 80.9648 1102.43 75.3604V5.12738H1108.47C1120.57 5.12738 1126.73 10.6125 1130.05 24.4444H1131.71L1127.92 0H1126.26Z"
      fill="#B7C1CD"
    />
    <path
      d="M1169.03 88C1186.22 88 1197.96 78.103 1197.96 63.1978C1197.96 31.8374 1152.08 40.6612 1152.08 17.29C1152.08 8.82385 1157.53 3.33875 1166.9 3.33875C1177.57 3.33875 1188 11.2087 1192.98 25.3984H1194.64L1190.73 0H1189.07C1186.58 10.2547 1183.26 0 1166.9 0C1150.06 0 1139.87 9.30081 1139.87 23.1328C1139.87 54.1355 1184.8 43.4038 1184.8 69.3984C1184.8 78.2222 1178.4 84.542 1169.03 84.542C1156.82 84.542 1147.57 76.4336 1140.58 59.6206H1138.92L1143.31 88H1144.97C1147.69 75.1219 1149.94 88 1169.03 88Z"
      fill="#B7C1CD"
    />
    <path
      d="M1243.62 0H1241.96L1212.33 71.3062C1208.42 80.7263 1204.86 84.4228 1199.29 84.542V86.2114H1223.83V84.542C1214.22 84.4228 1211.85 81.084 1215.53 72.3794L1221.1 58.6667H1253.11L1258.8 72.3794C1262.47 81.084 1260.34 84.4228 1250.62 84.542V86.2114H1287.72V84.542C1281.56 84.4228 1277.77 80.7263 1273.74 71.4255L1243.62 0ZM1236.99 19.6748L1251.69 55.3279H1222.52L1236.99 19.6748Z"
      fill="#B7C1CD"
    />
    <path
      d="M1326.74 88C1350.57 88 1368 69.0406 1368 42.0921C1368 18.8401 1352.71 0 1327.34 0C1303.39 0 1285.96 18.6016 1285.96 45.7886C1285.96 69.0406 1301.26 88 1326.74 88ZM1329.12 84.6613C1312.4 84.8997 1301.14 65.3442 1300.78 41.6152C1300.55 19.0786 1310.03 3.458 1324.97 3.33875C1341.68 3.21951 1352.83 22.5366 1353.18 46.3848C1353.42 68.8022 1343.82 84.4228 1329.12 84.6613Z"
      fill="#B7C1CD"
    />
  </svg>
);

export default SiteTitle;
